/** @jsx jsx */
import { jsx, Box, Flex, Themed } from 'theme-ui';
import { ButtonAction, Media } from '@sprinklr/shared-lib';
import SprinklrIcon from '@sprinklr/shared-lib/assets/svgs/sprinklr.svg';
import AppLogoBackgroundGrid from '@sprinklr/shared-lib/assets/images/AppLogoBackgroundGrid.jpg';
import videoPoster from '@sprinklr/shared-lib/assets/images/CXM-Screen.jpg';
import { useEffect } from 'react';

const hideLiveChat = () => {
  const liveChat = document.getElementById('spr-live-chat-app');
  if (liveChat && !liveChat.hidden) {
    liveChat.hidden = true;
  }
};

const AppLoginTemplate = () => {
  useEffect(() => {
    const hideChat = setInterval(() => hideLiveChat(), 100);
    return () => clearInterval(hideChat);
  }, []);

  const mediaProps = {
    mediaType: 'VIDEO',
    source: {
      file: {
        url: 'https://www.youtube.com/watch?v=ejAAsivx0JA&feature=emb_logo',
      },
    },
    // mobileImage: "",
    previewImage: { url: videoPoster },
    direction: 'ROW',
    // imageMaxWidth: "",
    imageAlignment: 'CENTER',
    // hideImageOnMobile: "",
    // hasTextContent: "",
    altTitle: 'Sprinklr CXM',
  };

  return (
    <Flex
      sx={{
        height: '100vh',
        flexWrap: 'wrap',
      }}
    >
      <Flex
        sx={{
          flex: '0 0 100%',
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          px: [3, 4, 5],
          position: 'relative',
          backgroundColor: '#f7f7f7',
          backgroundImage: `url(${AppLogoBackgroundGrid})`,
          backgroundSize: 'contain',
          backgroundPosition: ['73px 18px', 'right center'],
          backgroundRepeatX: 'no-repeat',
        }}
      >
        <Flex
          sx={{
            mx: [0, -3, -4],
            maxWidth: '875px',
            alignItems: 'center',
            flexDirection: ['column-reverse', null, 'row'],
            display: ['none', null, 'flex'],
          }}
        >
          <Box
            sx={{
              flex: ['100%', '0 0 50%'],
              maxWidth: ['100%', '65%', '50%'],
              px: [2, 3, 4],
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                mb: [3],
              }}
            >
              <SprinklrIcon
                sx={{
                  width: '50px',
                  height: '50px',
                  mr: [3],
                  flex: ['0 1 40px', '0 1 50px'],
                }}
              />
              <Themed.h1 sx={{ m: [0] }}> University</Themed.h1>
            </Flex>
            <Themed.p
              sx={{
                mb: [4],
                pb: [2],
              }}
            >
              Become a Sprinklr Customer Experience Management Pro in as little
              as 30 minutes. And the best part? It's FREE!
            </Themed.p>

            <ButtonAction
              variant="primary"
              url="https://university.sprinklr.com/"
              action={'NAVIGATE_TO_URL'}
            >
              Sign up today
            </ButtonAction>
          </Box>

          <Box
            sx={{
              flex: ['100%', '1 1 65%'],
              maxWidth: ['100%', '65%'],
              pl: [0, 3],
            }}
          >
            <Media {...mediaProps} />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AppLoginTemplate;
